<template>
  <div>
    <div class="advisory_box">
      <div class="advisory_item" @click="popupShow">
        <div class="image">
          <img src="../assets/ic_contact_a.png" alt="联系我们" />
        </div>
        <div class="text">联系我们</div>
      </div>
      <div class="advisory_item">
        <div class="image">
          <img src="../assets/ic_contact_b.png" alt="微信咨询" />
        </div>
        <div class="text">微信咨询</div>

        <div class="advisory_content">
          <div class="wechat">
            <img
              src="../assets/img_ewm_b.png"
              alt="扫描二维码添加微信好友"
            />
          </div>
        </div>
      </div>
      <div class="advisory_item">
        <div class="image">
          <img src="../assets/ic_contact_c.png" alt="热线电话" />
        </div>
        <div class="text">联系我们</div>
        <div class="advisory_content">
          <div class="phone">
            <img src="../assets/ic_phone_yellow.png" alt="" />
            <span>15817255623</span>
          </div>
        </div>
      </div>
      <div class="advisory_item" @click="runToTop">
        <div class="image">
          <img src="../assets/ic_contact_d.png" alt="回到顶部" />
        </div>
        <div class="text">回到顶部</div>
      </div>
    </div>

    <div
      class="service_popup"
      :style="{
        left: popupLeft + 'px',
        top: popupTop + 'px',
        width: popupWidth + 'px',
        height: popupHeight + 'px',
        borderRadius: borderRadius + 'px',
        opacity: opacity,
      }"
    >
      <div class="service_popup_content">
        <img class="bg" src="../assets/img_contact us.png" />
        <div class="close" @click="contactClose"></div>
        <div class="contact_box">
          <div v-show="!callImg" class="contact_info_box">
            <h6>获得产品报价方案</h6>
            <p>产品经理一对一沟通指导</p>
            <div>扫码添加[产品经理]沟通方案</div>
            <button @click="callImg = true">立即沟通</button>
          </div>
          <div v-show="!callImg" class="contact_code_box">
            <img class="chat" src="../assets/img_copywriting.png" />
            <img
              class="contact_code"
              src="../assets/img_ewm_b.png"
            />
          </div>
          <div v-show="callImg" class="call_box">
            <img
              v-show="callImg"
              src="../assets/img_ewm_b.png"
            />
            <p>扫码添加[产品经理]沟通方案</p>
          </div>
          
        </div>
      </div>
    </div>

    <!-- 加载动画 -->
    <loading v-model="loading" />
  </div>
</template>

<script>
let clearTime = undefined;
import { mapState, mapMutations } from "vuex";
// 加载动画
import loading from "@/components/common/loading";
export default {
  components: {
    loading,
  },
  data() {
    return {
      contactPopupShow: false,
      popupLeft: 62,
      popupTop: "inject",
      popupWidth: 60,
      popupHeight: 60,
      borderRadius: 30,
      opacity: 0,
      callImg: false,
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  created() {
    this.setCacheData();
    if (process.browser) {
      this.popupTop = document.body.clientHeight / 2 + 40;
    }
    
    this.popupLeft = document.body.clientWidth - 60;
  },
  mounted() {
    clearTime = setTimeout(() => {
      this.popupShow();
    }, 7000);
  },
  methods: {
    ...mapMutations(["setCacheData"]),
    onPageJump(url) {
      this.$router.push(url);
    },
    contactClose() {
      // this.popupLeft = 62;
      this.popupLeft = document.body.clientWidth - 60;
      this.popupTop = document.body.clientHeight / 2 + 40;
      this.popupWidth = 60;
      this.opacity = 0;
      this.borderRadius = 30;
      this.popupHeight = 60;
      clearTime && clearTimeout(clearTime);
      clearTime = setTimeout(() => {
        this.popupShow();
      }, 60000);
      this.callImg = false
    },
    popupShow() {
      this.popupLeft = document.body.clientWidth / 2;
      this.popupTop = document.body.clientHeight / 2;
      this.borderRadius = 12;
      this.popupWidth = 600;
      this.popupHeight = 660;
      this.opacity = 1;
    },
    onQQService() {
      this.$nextTick(() => {
        let nodeboard = document.querySelectorAll("#nb_icon_wrap");
        if (nodeboard && nodeboard.length > 0) {
          nodeboard[0].click();
        }
      });
      // window.open(
      //   "http://wpa.qq.com/msgrd?v=3&uin=3364556039&site=qq&menu=yes"
      // );
      // window.open("tencent://message/?uin=3364556039&Site=https://kemean.com&Menu=yes");
    },
    runToTop() {
      // toTop 滚动到顶部
      let currentPosition = 0;
      const speed = 15;
      const timer = setInterval(function () {
        currentPosition =
          document.documentElement.scrollTop || document.body.scrollTop;
        currentPosition -= speed; // speed变量
        if (currentPosition > 0) {
          window.scrollTo(0, currentPosition);
        } else {
          window.scrollTo(0, 0);
          clearInterval(timer);
        }
      }, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.advisory_box {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  width: 48px;
  padding: 40px 0;
  background-color: rgba($color: #000000, $alpha: 0.6);
  border-radius: 20px 0px 0px 20px;

  .advisory_item {
    position: relative;
    padding: 0 12px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    opacity: 0.6;

    .image {
      margin-bottom: 12px;
    }

    &:hover {
      opacity: 1;
      .advisory_content {
        display: flex;
      }
    }

    & + .advisory_item {
      margin-top: 20px;
    }

    .advisory_content {
      display: none;
      position: absolute;
      top: 50%;
      transform: translate(-100%, -50%);
      padding: 20px;
      margin-left: -32px;
      background: rgba($color: #000000, $alpha: 0.6);
      border-radius: 8px;

      &::after {
        content: "";
        // opacity: 0.6;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(100%, -50%);
        border-left: 16px solid rgba($color: #000000, $alpha: 0.6);
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
      }

      .phone {
        display: flex;
        color: #fff7b1;
        img {
          width: 18px;
          height: 18px;
          margin-right: 12px;
        }
      }

      .wechat {
        img {
          width: 140px;
          height: 140px;
          border-radius: 20px;
          display: block;
        }
      }
    }
  }

  //   .advisory_item {
  //     width: 60px;
  //     height: 60px;
  //     margin: 20px 0;
  //     position: relative;
  //     cursor: pointer;
  //     &.arrow_top {
  //       .image img {
  //         width: 28px;
  //         height: 33px;
  //       }
  //       &:hover {
  //         .image {
  //           opacity: 1;
  //         }
  //       }
  //     }
  //     .image {
  //       width: 60px;
  //       height: 60px;
  //       background-color: #527fff;
  //       border-radius: 50%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       position: absolute;
  //       z-index: 2;
  //       top: 0;
  //       left: 0;
  //       transition: all 0.4s;
  //       img {
  //         height: 24px;
  //       }
  //     }
  //     .text {
  //       font-size: 14px;
  //       color: #ffffff;
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       width: 60px;
  //       height: 60px;
  //       background-color: #527fff;
  //       border-radius: 50%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       flex-direction: column;
  //       z-index: 2;
  //       opacity: 0;
  //       transition: all 0.4s;
  //     }

  //     &:hover {
  //       .text {
  //         opacity: 1;
  //       }
  //       .image {
  //         opacity: 0;
  //       }
  //       .advisory_content {
  //         display: flex;
  //       }
  //       &::before {
  //         content: "";
  //         position: absolute;
  //         top: 50%;
  //         right: 50%;
  //         transform: translateX(-50%) translateY(-50%) scale(0.6);
  //         background-color: rgba(82, 127, 255, 0.5);
  //         width: 100px;
  //         height: 100px;
  //         border-radius: 50%;
  //         animation-name: advisoryWave;
  //         animation-duration: 1.6s;
  //         animation-timing-function: linear;
  //         animation-iteration-count: infinite;
  //       }
  //       &::after {
  //         content: "";
  //         position: absolute;
  //         top: 50%;
  //         right: 50%;
  //         transform: translateX(-50%) translateY(-50%) scale(0.6);
  //         background-color: rgba(82, 127, 255, 0.5);
  //         width: 100px;
  //         height: 100px;
  //         border-radius: 50%;
  //         animation-name: advisoryWave;
  //         animation-duration: 1.6s;
  //         animation-delay: 0.8s;
  //         animation-timing-function: linear;
  //         animation-iteration-count: infinite;
  //       }
  //     }
  //     .advisory_content {
  //       position: absolute;
  //       top: 50%;
  //       transform: translateY(-50%);
  //       left: 60px;
  //       padding-left: 12px;
  //       display: none;
  //       align-items: center;
  //       animation-name: navAnimation;
  //       animation-duration: 0.6s;
  //       animation-direction: alternate;
  //       &::before {
  //         content: "";
  //         width: 0;
  //         height: 0;
  //         border-top: 10px solid transparent;
  //         border-bottom: 10px solid transparent;
  //         border-right: 10px solid #527fff;
  //       }
  //       .content {
  //         background-color: #527fff;
  //         border-radius: 25px;
  //         padding: 10px;
  //         white-space: nowrap;
  //         img {
  //           width: 150px;
  //           height: 150px;
  //           border-radius: 20px;
  //           display: block;
  //         }
  //         p {
  //           white-space: nowrap;
  //           color: #ffffff;
  //           padding: 5px 20px;
  //           font-size: 14px;
  //           a {
  //             font-size: 14px;
  //             font-weight: bold;
  //           }
  //         }
  //       }
  //     }
  //   }
}

.service_popup {
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  width: 600px;
  height: 500px;
  z-index: 9;
  overflow: hidden;
  transition: all 0.4s;
  padding-top: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    @include bis("./../assets/ic_contactus_close.png");
    cursor: pointer;
  }
  .bg {
    width: 560px;
    height: 195px;
    margin-top: -53px;
    border-radius: 8px 8px 0px 0px;
  }
  .service_popup_content {
    background-color: #ffffff;
    box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    width: 560px;
    position: relative;

    .contact_box {
      padding: 24px 50px;
      display: flex;
      justify-content: space-between;
      .contact_info_box {
        display: flex;
        flex-direction: column;
        width: 293px;
        border-right: 1px solid #f1f1f1;
        padding-right: 40px;
        h6 {
          font-size: 24px;
          color: #333333;
          font-weight: bold;
        }
        p {
          font-size: 18px;
          color: #333333;
          margin-top: 12px;
        }
        div {
          margin-top: 24px;
          border-top: 1px solid #f1f1f1;
          padding-top: 24px;
          font-size: 14px;
          color: #999999;
          margin-right: 73px;
        }
        button {
          width: 200px;
          height: 46px;
          background-color: rgb(88, 66, 242);
          box-shadow: 0px 2px 6px 0px rgba(0, 59, 225, 0.4);
          margin-top: 24px;
          font-size: 18px;
          color: #ffffff;
          border-radius: 23px;
        }
      }
      .contact_code_box {
        width: 130px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .chat {
          width: 120px;
          height: 72px;
        }
        .contact_code {
          width: 130px;
          height: 130px;
          margin-top: 12px;
        }
      }
    }

    .call_box{
      width: 100%;
      text-align: center;
      img{
        width: 180px;
        margin-bottom: 12px;
      }
    }
  }
}
</style>