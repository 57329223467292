<template>
  <div>
    <router-view />
    <!-- 加载动画 -->
    <z-loading v-model="loading" />
    <!-- 侧边栏 -->
    <z-sidebar></z-sidebar>
  </div>
</template>
<script>
// 加载动画
import zLoading from "@/components/common/loading";
// 侧边栏
import zSidebar from "@/components/sidebar";
import { mapState, mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    zLoading,
    zSidebar,
  },
  computed: {
    ...mapState(["loading"]),
  },
  created() {
    this.setCacheData();
  },
  methods: {
    ...mapMutations(["setCacheData"]),
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "@/styles/reset.scss";
@import "@/styles/common.scss";
/* 导入主题样式 */
@import "~vue-slider-component/lib/theme/default.scss";
</style>
