<template>
  <div>
    <!-- 头部 -->
    <header class="header" :class="{ white: white || scrollState, line: line || scrollState }">
      <div class="header_center">
        <div class="left_info">
          <h1>
            <a class="logo" href="/" :class="{ 'router-link-active': $route.path == '/' }">
              <img v-if="white || scrollState" src="@/assets/ic_logo_actv.png" alt="深圳APP开发公司" />
              <img v-else src="@/assets/ic_logo.png" alt="深圳APP开发公司" />
              <img v-if="white || scrollState" class="highlight" src="@/assets/icon_highlight.png" alt="深圳APP开发公司" />
            </a>
          </h1>
          <div class="line"></div>
          <span>服务为先 · 技术为本</span>
        </div>
        <div class="nav_box">
          <div class="nav_item">
            <router-link tag="a" to="/">首页</router-link>
            <!-- <a href="/" :class="{ 'router-link-active': $route.path == '/' }">首页</a> -->
            <!-- <router-link tag="a" to="/#">首页</router-link> -->
          </div>
          <div class="nav_item">
            <span class="arrow">开发服务</span>
            <div class="content_arrow development_service_arrow"></div>
            <div class="nav_content development_service">
              <div class="page_center">
                <a v-for="(item, index) of headServiceLinkData" :key="index" class="service_list" :href="
                    item.jumpRecord && item.jumpRecord.webViewUrl
                      ? item.jumpRecord.webViewUrl
                      : '#'
                  " target="_blank">
                  <img :src="item.img" :alt="item.name" />
                  <div class="service_info">
                    <div class="name">{{ item.name }}</div>
                    <p>{{ item.featuresDesc }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="nav_item">
            <span class="arrow">运营服务</span>
            <div class="nav_content operation">
              <div class="content_arrow"></div>
              <router-link class="content_tab" tag="div" to="/construction">创意品牌型网站建设</router-link>
              <router-link class="content_tab" tag="div" to="/optimize">SEO整站优化</router-link>
            </div>
          </div>
          <div class="nav_item">
            <span class="arrow">系统方案</span>
            <div class="nav_content operation">
              <div class="content_arrow"></div>
              <router-link class="content_tab" tag="div" to="/program">电商解决方案</router-link>
              <router-link class="content_tab" tag="div" to="/allProgram">全行业开发解决方案</router-link>
            </div>
          </div>
          <div class="nav_item">
            <router-link tag="a" to="/case">合作案例</router-link>
          </div>
          <div class="nav_item">
            <router-link tag="a" to="/skill">商学院</router-link>
          </div>
          <div class="nav_item">
            <router-link tag="a" to="/about" rel="nofollow">关于我们</router-link>
          </div>
        </div>
      </div>
    </header>
    <div v-if="white" class="standing"></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },
    line: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollState: false,
      serviceList: [],
      headServiceLinkData: {}
    };
  },
  created() {
    this.pageData();
  },
  mounted() {
    const _this = this;
    this.onPageScroll();
    window.onscroll = this.onPageScroll;
  },
  methods: {
    onPageJump(url) {
      this.$router.push(url);
    },
    onPageScroll() {
      if (!this.white) {
        const scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 300) {
          this.scrollState = true;
        } else {
          this.scrollState = false;
        }
      } else {
        this.scrollState = true;
      }
    },
    pageData() {
      // 轮播图
      this.$http.get('api/service_link/list?type=1100').then(res => {
        this.headServiceLinkData = res.data;
      });
    },
    onService(item) {
      if (item.jumpType === 1101 || item.jumpType === 1201) {
        window.open(item.jumpRecord.webViewUrl);
      } else if (item.jumpType === 1301) {
        window.open(item.jumpRecord.videoUrl);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.standing {
  height: 80px;
}
@keyframes logoHighlight {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(187px);
    opacity: 0;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: all 0.6s;
  &.white {
    background-color: #ffffff;
    .header_center {
      .left_info {
        .line {
          background-color: #333333;
        }
        span {
          color: #333333;
        }
      }
      .nav_box .nav_item {
        > a,
        > span {
          color: #999999;
          &.router-link-active {
            color: #333333;
          }
          &:hover {
            color: #333333;
          }
        }
        .arrow::after {
          content: "";
          border-top: 6px solid #333;
        }
      }
    }
  }
  &.line {
    border-bottom: solid 1px #e5e5e5;
  }
  .header_center {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1380px;
    min-width: 1200px;
    margin: 0 auto;
    .left_info {
      display: flex;
      align-items: center;
      .logo {
        width: 187px;
        height: 50px;
        display: block;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
        .highlight {
          position: absolute;
          left: 0;
          top: -5px;
          width: 68px;
          height: 60px;
          z-index: 2;
          opacity: 0;
          animation-name: logoHighlight;
          animation-duration: 1s;
          // animation-iteration-count: infinite;
        }
      }
      .line {
        width: 1px;
        height: 24px;
        background-color: rgba(255, 255, 255, 1);
        margin: 0 24px;
      }
      span {
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
      }
    }
    .nav_box {
      display: flex;
      align-items: center;
      .nav_item {
        height: 80px;
        margin-left: 46px;
        position: relative;
        &:hover .nav_content,
        &:hover .development_service_arrow {
          display: flex;
        }
        > a,
        > span {
          height: 80px;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.6);
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          &.router-link-active {
            color: #fff;
            // &::before {
            //   position: absolute;
            //   left: 50%;
            //   bottom: 25px;
            //   transform: translateX(-50%);
            //   content: "";
            //   width: 20px;
            //   height: 1px;
            //   background-color: #fff;
            // }
          }
          &:hover {
            color: #fff;
          }
        }
        .arrow::after {
          content: "";
          margin-left: 11px;
          width: 0;
          height: 0;
          border-top: 6px solid #fff;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
        }
      }
    }
  }
}

.nav_content {
  top: 80px;
  display: none;
  animation-name: navAnimation;
  animation-duration: 0.6s;
  animation-direction: alternate;
  background-color: #fff;
  padding: 20px 0;
  border-top: 1px solid #e5e5e5;
  .content_arrow {
    position: absolute;
    top: -16px;
    left: 50%;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 16px solid #e5e5e5;
    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 50%;
      width: 0;
      height: 0;
      transform: translateX(-50%);
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 16px solid #fff;
    }
  }
}
@keyframes navAnimation {
  0% {
    opacity: 0;
    // transform: scale(0.5);
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes navArrowAnimation {
  0% {
    opacity: 0;
    // transform: scale(0.5);
    transform: translateY(-50px) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) translateX(-50%);
  }
}
.development_service_arrow {
  position: absolute;
  left: 50%;
  top: 65px;
  width: 0;
  height: 0;
  display: none;
  transform: translateX(-50%);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 16px solid #e5e5e5;
  animation-name: navArrowAnimation;
  animation-duration: 0.6s;
  animation-direction: alternate;
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 50%;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 16px solid #fff;
  }
}
.development_service {
  position: fixed;
  width: 100%;
  left: 0;
  .page_center {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .service_list {
      width: 49.5%;
      height: 100px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      padding: 0 24px;
      background-color: #f7f7f7;
      border-radius: 12px;
      cursor: pointer;
      img {
        margin-right: 24px;
        width: 40px;
        height: 40px;
      }
      .service_info {
        .name {
          font-size: 14px;
          color: #333333;
        }
        p {
          font-size: 12px;
          color: #999999;
          margin-top: 20px;
        }
      }
    }
  }
}
.operation {
  left: 50%;
  margin-left: -70px;
  position: absolute;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  width: 140px;
  flex-direction: column;
  padding: 0;
  .content_tab {
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
</style>
